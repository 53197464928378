var render = function render(){var _vm=this,_c=_vm._self._c;return _c('row',{attrs:{"wrap":""}},[_c('column',{staticClass:"w-full lg:w-1/2 mb-2"},[_c('radio-select',{attrs:{"items":_vm.predefinedDenominations,"selected":_vm.selectedPredefinedDenomination},on:{"input":_vm.handlePredefinedDenominationInput},scopedSlots:_vm._u([{key:"item",fn:function({ item, selected }){return [_c('div',{staticClass:"px-1 md:px-3 py-4 leading-normal text-center",class:selected ? 'font-bold text-link' : '',attrs:{"data-test":"custom-denoms-predefined-value"}},[_vm._v("\n          "+_vm._s(item.label)),_c('small',[_vm._v(_vm._s(_vm.currency))])])]}}])})],1),_vm._v(" "),_c('column',{staticClass:"w-full lg:w-1/2 lg:mb-2"},[_c('ui-input',{attrs:{"margin":'lg:mb-2',"value":_vm.chosenPrice,"has-error":_vm.$v.chosenPrice.$invalid,"is-dirty":_vm.$v.chosenPrice.$dirty,"extended-wrapper-classes":"min-h-22","autocomplete":"off","selected":!!_vm.chosenPrice && !_vm.$v.chosenPrice.$invalid,"is-selectable":"","extended-input-classes":"rounded leading-normal","is-big":"","extended-label-classes":"leading-relaxed","data-test":"custom-denoms-custom-value-input","information-message":_vm.$v.chosenPrice.$invalid
          ? `${_vm.$t(
              'molecules.custom_denomination.out_of_range_error'
            )} ${_vm.minValue}-${_vm.maxValue}`
          : '',"label":`${_vm.$t(
          'molecules.custom_denomination.input_label'
        )} (${_vm.minValue}${_vm.currency}-${_vm.maxValue}${_vm.currency})`},on:{"input":_vm.handleCustomDenominationInput,"keypress":_vm.handleKeyPress,"paste":function($event){return _vm.handlePaste($event)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }