
import { between } from 'vuelidate/lib/validators'
import { RadioSelect, UiInput } from '~/components/atoms'
import { Row, Column } from '~/components/grid'
import { unitsToCents, centsToUnits } from '~/utils/prices'

export default {
  components: {
    RadioSelect,
    UiInput,
    Row,
    Column,
  },
  props: {
    customDenomination: {
      type: Object,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    preselectedValue: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selectedPrice: unitsToCents(this.preselectedValue),
      chosenPrice: this.preselectedValue,
    }
  },
  computed: {
    predefinedDenominations() {
      return (this.customDenomination?.predefinedValues || []).map(
        predefinedValue => ({
          value: predefinedValue,
          label: centsToUnits(predefinedValue),
        })
      )
    },
    selectedPredefinedDenomination() {
      return this.predefinedDenominations.find(
        ({ value }) => value === this.selectedPrice
      )
    },
    minValue() {
      return centsToUnits(this.customDenomination.minValue)
    },
    maxValue() {
      return centsToUnits(this.customDenomination.maxValue)
    },
  },
  validations() {
    return {
      chosenPrice: {
        between: between(this.minValue, this.maxValue),
      },
    }
  },
  mounted() {
    this.handleCustomDenominationInput(
      centsToUnits(
        this.selectedPrice ||
          this.predefinedDenominations?.[2]?.value ||
          this.predefinedDenominations?.[0]?.value ||
          0
      )
    )
  },
  methods: {
    handlePredefinedDenominationInput(predefinedDenomination) {
      this.chosenPrice = centsToUnits(predefinedDenomination.value)
      this.selectedPrice = predefinedDenomination.value
      this.$emit('input', this.selectedPrice)
    },
    handleCustomDenominationInput(chosenPrice) {
      this.chosenPrice = chosenPrice
      this.$v.chosenPrice.$model = chosenPrice

      if (!chosenPrice) return
      const chosenPriceInt = this.$v.chosenPrice.$model
        ? parseInt(this.$v.chosenPrice.$model, 10)
        : 0

      const centsValue = unitsToCents(chosenPriceInt)
      this.selectedPrice = this.predefinedDenominations.find(
        denom => denom.value === centsValue
      )?.value

      const isInRange =
        chosenPriceInt <= this.maxValue && chosenPriceInt >= this.minValue
      this.$emit('input', isInRange ? centsValue : null)
    },
    handlePaste(event) {
      event.preventDefault()
      const text = event.clipboardData.getData('text')
      const numbers = text.match(/\d+/g)

      const cleanNumber = numbers.reduce((acc, curr) => `${acc}${curr}`, '')

      this.chosenPrice = cleanNumber
      this.$v.chosenPrice.$model = cleanNumber
    },
    handleKeyPress(event) {
      if (/\D+/.test(event.key)) event.preventDefault()
    },
  },
}
